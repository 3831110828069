@import "src/scss/module";

.AreasOfExpertise {
  position: relative;

  .bg_1 {
    left: -2rem;
    bottom: -10rem;
    z-index: -3;
  }

  .bg_2 {
    left: 0;
    bottom: -4rem;
    z-index: -2;
  }

  .vector_1 {
    position: absolute;
    left: -0.5rem;
    top: -7.5rem;
    z-index: 1;
    width: 105px;
  }

  .vector_2 {
    position: absolute;
    z-index: -4;
    top: -10rem;
    width: 800px;
    left: 50%;
    transform: translateX(-50%);

    @include media-breakpoint-up(sm) {
      top: -14rem;
    }

    @include media-breakpoint-up(md) {
      top: -20rem;
      width: 1388px;
      left: 40%;
      transform: initial;
    }

    @include media-breakpoint-up(lg) {
      top: -8rem;
    }
  }

  .description {
    clip-path: #{$hexagon-clip-path};
    background-color: #{$navy};
    color: #{$white};
    width: 400px;
    position: absolute;
    top: 50%;
    transition: 0.5s;
    transform: translateY(-65%);
    right: initial;
    left: -50px;

    @include media-breakpoint-up(md) {
      left: initial;
      right: 0;
      transform: translateY(-50%);
    }

    & > div {
      transition: 0.5s;
      opacity: 0;
    }

    &:global(.animate-end) {
      transform: translateY(-85%);
      z-index: 1;

      & > div {
        opacity: 1;
      }
    }
  }

  .image {
    clip-path: #{$hexagon-clip-path};
    width: 100%;
    min-width: 400px;
    max-width: 500px;
    background-color: #{$navy};
    transition: 0.5s;
    margin-left: -75px;
    transform: scale(1) translateX(3rem) translateY(-1rem);

    @include media-breakpoint-up(md) {
      transform: scale(1) translateX(7rem) translateY(3rem);
    }

    & > img:nth-of-type(1) {
      transition: 0.5s;
      opacity: 0;
      z-index: 1;
    }

    &:global(.animate-end) {
      transform: scale(1.1) translateX(0rem) translateY(-4rem);

      @include media-breakpoint-up(md) {
        transform: scale(1.1) translateX(0rem) translateY(25%);
      }

      & > img:nth-of-type(1) {
        opacity: 1;
      }
    }
  }

  :global {
    .nav {
      border-left: 2px solid rgba($white, 0.5);
    }

    .nav-item {
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .nav-link {
      color: inherit;
      letter-spacing: -1px;
      font-weight: #{$headings-font-weight};

      &.active {
        position: relative;
        color: #{$green};

        &:before {
          position: absolute;
          display: block;
          content: "";
          width: 1px;
          left: -1px;
          top: 50%;
          transform: translateY(-50%);
          background-color: #{$green};
          animation: height 0.5s forwards;
        }
      }
    }
  }
}
